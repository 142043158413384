<template>
  <div>
    <Menu />
    <div class="fixed-tags bg-light" style="width: 40px; border-top-left-radius: 30px; border-bottom-left-radius: 30px;">
      <div class="container mt-2 mb-2">
        <div class="row">
          <div class="col-12 text-end">
            <span style="font-size: 20px; color: #000;"><i class="bi bi-share-fill"></i></span><br/>
            <a style="font-size: 20px;" :href="facebookShareLink" target="_blank">
              <i class="bi bi-facebook switch-blue"></i>
            </a><br/>
            <a style="font-size: 20px;" :href="pinterestShareLink" target="_blank">
              <i class="bi bi-pinterest switch-blue"></i>
            </a><br/>
          </div>
        </div>
      </div>
    </div>

    <!-- add new articles here -->
    <section v-if="this.$route.params.articleId === 'the-finer-things'">
      
      <div class="container-fluid g-0 bg-light pt-4 pb-4">
        <div class="row g-0">
          <div class="col-md-7">
            <img src = "/images/lifestyle/amazing-cars.jpg" class="w-100" style="border-bottom-right-radius: 30px;" />
          </div>
          <div class="col-md-4 p-5">
            <h2 class="text-start text-uppercase"><strong>An appreciation for the finer things in life</strong></h2>
            <p>Luxury living is often associated with an appreciation for the finer things in life, such as supercars, fine wines, and rare art pieces as a means to express style and taste.</p>
          </div>
        </div>
      </div>
            
      <div class="wrapper5">
        <div class="container px-5">
          <div class="row justify-content-center ">
            <div class="col-md-10 pt-5 text-start">
              <p>The exceptionally wealthy also famously love under-the-radar status items that only their peers will recognise, as a tacit affirmation of their shared superior status. This is known as stealth wealth and is currently being echoed in a distinctly understated style of décor.</p>
              <p>Investing in luxury cars, from classics like the Ferrari 250 GTO to contemporary supercars, does offer the potential for impressive returns, but demands a passion for cars and diligent research – something that not everyone has the time or inclination for.</p>
              <p>However, investment in art and other coveted objects has emerged in recent years as an alternative option for astute buyers seeking to diversify their investment portfolios while also acquiring beautiful belongings. Buying art for a luxury home goes beyond an investment, it adds soul to a living space and creates a strong sense of personal style. High-end art also offers longevity with little to no maintenance requirements – and never goes out of style.</p> 
              <p>And now, Vantage Fine Automotive Art offers affluent buyers an opportunity to combine their love of luxury cars with the advantages of high-end art investments that speak directly to their passions.</p>
              <p>Offering limited edition prints, bespoke motoring books, and custom posters, Vantage Fine Auto Art is perfectly positioned to provide the perfect décor pieces for affluent motoring enthusiasts.</p>
              <p>These extraordinary objects will create interest and even envy, whether you choose to hang them in your office, living room, or guest suite, or display them on your entrance hall or coffee table. And they will certainly make great conversation-starters among your guests.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-5 mb-5">
        <div class="row justify-content-center">
          <div class="col-md-4 text-end">
            <p><small><strong>South African Equestrian Estates</strong><br/><a style="color: #000;" href="/article/south-african-equestrian-estates"><i class="bi bi-arrow-left"></i> Previous</a></small></p>
          </div>
          <div class="col-md-1">
            <img style="border-bottom-left-radius: 30px;" src="/images/lifestyle/blog-polo.png" class="img img-fluid">
          </div>
          <div class="col-md-1">
            <img style="border-top-right-radius: 30px;" src="/images/lifestyle/10-must-have-features.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4">
            <p><small><strong>10 must-have features for luxury homes in 2024</strong><br/><a style="color: #000;" href="/article/10-must-have-features">Next <i class="bi bi-arrow-right"></i></a></small></p>
          </div>
        </div>
      </div>

    </section>

    <section v-if="this.$route.params.articleId === 'amazing-suburbs'">
      
      <div class="container-fluid g-0 bg-light pt-4 pb-4">
        <div class="row g-0">
          <div class="col-md-7">
            <img src = "/images/lifestyle/amazing-suburbs.jpg" class="w-100" style="border-bottom-right-radius: 30px;" />
          </div>
          <div class="col-md-4 p-5">
            <h2 class="text-start text-uppercase"><strong>Exploring South Africa’s most expensive suburbs</strong></h2>
            <p>Globally, South Africa ranks as one of the 20 biggest prime residential markets, boasting some of the world’s most exclusive and upmarket residential areas.</p>
          </div>
        </div>
      </div>
            
      <div class="wrapper5">
        <div class="container px-5">
          <div class="row justify-content-center ">
            <div class="col-md-10 pt-5 text-start">
              <p>In 2023, the annual Africa Wealth Report, the definitive guide to Africa’s wealth and luxury sector, revealed that there are more than 3700 homes in SA valued at more than USD1m (approx. R18,5m at the time) and that Clifton, Bantry Bay, Fresnaye, Bishopscourt, Camps Bay & Bakoven, and Llandudno were South Africa’s most exclusive suburbs.</p>
              <p>This year, Cape Town continues to dominate the list of SA’s 10 most expensive suburbs, with Clifton, Bantry Bay, and Llandudno heading the list, followed by Bishopscourt, Higgovale, the V&A Waterfront, Fresnaye, Camps Bay, and Upper Constantia, according to property data company Lightstone.</p>
              <p>This is a testament not only to the allure of Cape Town but to the confidence that buyers continue to have in its property market. </p>
              <p>Homes in these exclusive suburbs command an average price of well over R10m, with the most expensive often topping R20m. </p>
              <p>In Clifton, the most expensive suburb in South Africa, Lightstone figures show that homes currently command a median selling price of R25m, with the highest price achieved in 2023 being a whopping R150m.</p>
              <p>The second most expensive suburb is Bantry Bay with a median price of R22m, while Llandudno comes in third with a median of R19,95m. The only Top 10 suburb outside of Cape Town – Sandhurst in Sandton/Johannesburg – is the fourth most expensive, with a median home price of R19m.</p>
              <p>And while Cape Town suburbs remain dominant, there are other areas where square meter prices are catching up. These include Beachy Head Drive in Plettenberg Bay, Eastcliff in Hermanus and Lagoon Drive in Umhlanga.</p>
              <p>Meanwhile, the latest data shows that the average home price in Cape Town is now around R2,1m, with Johannesburg at R1,6m, Pretoria at R1,5m, and Durban at R1,3m. In Sandton specifically, the average is R2,3m while on the Atlantic Seaboard in Cape Town, the average is R8,2m. </p>
              <p>The latest statistics from StatsSA indicate that the average transaction price for Cape Town has risen 28% over the past five years (2019-2023), while that in Johannesburg has risen only 9%. </p>
              <p>Chas Everitt Luxury specialises in finding luxury buyers their ideal homes in the most expensive suburbs in SA. Offering an extensive range of opulent and exclusive homes, Chas Everitt Luxury can assist even the most discerning buyers.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-5 mb-5">
        <div class="row justify-content-center">
          <div class="col-md-4 text-end">
            <p><small><strong>South African Equestrian Estates</strong><br/><a style="color: #000;" href="/article/south-african-equestrian-estates"><i class="bi bi-arrow-left"></i> Previous</a></small></p>
          </div>
          <div class="col-md-1">
            <img style="border-bottom-left-radius: 30px;" src="/images/lifestyle/blog-polo.png" class="img img-fluid">
          </div>
          <div class="col-md-1">
            <img style="border-top-right-radius: 30px;" src="/images/lifestyle/10-must-have-features.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4">
            <p><small><strong>10 must-have features for luxury homes in 2024</strong><br/><a style="color: #000;" href="/article/10-must-have-features">Next <i class="bi bi-arrow-right"></i></a></small></p>
          </div>
        </div>
      </div>

    </section>

    <section v-if="this.$route.params.articleId === 'luxury-watches-and-luxury-properties'">
      
      <div class="container-fluid g-0 bg-light pt-4 pb-4">
        <div class="row g-0">
          <div class="col-md-7">
            <img src = "/images/lifestyle/watches-and-properties.jpg" class="w-100" style="border-bottom-right-radius: 30px;" />
          </div>
          <div class="col-md-4 p-5">
            <h2 class="text-start text-uppercase"><strong>What do luxury watches and luxury properties have in common?</strong></h2>
            <p>We recently sat down with Johan Dreyer, Founder and CEO of Topwatch, to discuss the intersection of luxury watches and luxury properties.</p>
          </div>
        </div>
      </div>
            
      <div class="wrapper5">
        <div class="container px-5">
          <div class="row justify-content-center ">
            <div class="col-md-10 pt-5 text-start">
              <p>They are both considered staples in a luxury investment portfolio and share quite a few commonalities:</p>
              <h3>Diversification</h3>
              <p>Property and luxury watches play different but complementary roles in a portfolio. Property offers stability and long-term growth, while also potentially generating rental income, in addition to appreciating in value.</p>
              <p>Luxury watches are liquid assets. They can appreciate over time and are easier (and quicker) to buy and sell compared to real estate. The combination of the two helps you create a well-rounded portfolio that can withstand economic fluctuations.</p>
              <h3>They normally protect against inflation</h3>
              <p>Inflation can erode the value of certain assets. Both property and watches allow you to hedge against inflation. Historically, real estate has been known to outperform or at least keep level with inflation.</p>
              <p>Similarly with luxury watches, especially those crafted from precious metals, have been known to retain or even increase in value over time.</p>
              <h3>They are tangible assets with intrinsic value</h3>
              <p>Intangible assets, such as stocks and bonds, are non-physical assets that have a theoretical value which fluctuates based on whether the market attributes value to them or not.</p>
              <p>On the other hand, property and watches possess a physical presence and inherent usefulness, thereby proving their worth as stable investments.</p>
              <h3>Different economic factors affect each asset</h3>
              <p>The economic factors that affect the property market are not necessarily the same ones that influence the prices of watches. This results in a more balanced portfolio.</p>
              <p>The property market is primarily influenced by factors such as interest rates, job markets, and overall economic health. On the other hand, the luxury watch market is more driven by factors like brand reputation, collectability, and even trends in fashion and pop culture.</p>
              <h3>Potential tax benefits</h3>
              <p>Both property and luxury watches can offer tax breaks depending on several factors. If you rent out your property, you may be able to deduct certain expenses related to maintaining the property. You might also be eligible for a capital gains tax exclusion if you’re selling your primary residence at a profit.</p>
              <p>In some cases, a watch is considered a collectible. Collectibles can qualify for a lower capital gains tax rate compared to regular assets.</p>
              <h3>The rare combination of true value & lifestyle benefits</h3>
              <p>Luxury watches and properties epitomise the luxury lifestyle and have an aesthetic appeal. Most importantly, they are also two assets that appreciate over time.</p>
              <p><strong>This unique combination makes them highly coveted assets, both individually and as a pair.</strong></p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-5 mb-5">
        <div class="row justify-content-center">
          <div class="col-md-4 text-end">
            <p><small><strong>South African Equestrian Estates</strong><br/><a style="color: #000;" href="/article/south-african-equestrian-estates"><i class="bi bi-arrow-left"></i> Previous</a></small></p>
          </div>
          <div class="col-md-1">
            <img style="border-bottom-left-radius: 30px;" src="/images/lifestyle/blog-polo.png" class="img img-fluid">
          </div>
          <div class="col-md-1">
            <img style="border-top-right-radius: 30px;" src="/images/lifestyle/10-must-have-features.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4">
            <p><small><strong>10 must-have features for luxury homes in 2024</strong><br/><a style="color: #000;" href="/article/10-must-have-features">Next <i class="bi bi-arrow-right"></i></a></small></p>
          </div>
        </div>
      </div>

    </section>

    <section v-if="this.$route.params.articleId === 'the-gallery-steyn-city'">
      
      <div class="container-fluid g-0 bg-light pt-4 pb-4">
        <div class="row g-0">
          <div class="col-md-7">
            <img src = "/images/lifestyle/steyn-city.jpg" class="w-100" style="border-bottom-right-radius: 30px;" />
          </div>
          <div class="col-md-4 p-5">
            <h2 class="text-start text-uppercase"><strong>The Gallery at Steyn City: Contemporary art with a local twist</strong></h2>
            <p>When The Gallery at Steyn City opened its doors on 22 March 2023, the Johannesburg art scene was blessed with a prestigious exhibit of exquisite artwork and a new home for art collectors, investors and enthusiasts.</p>
          </div>
        </div>
      </div>
            
      <div class="wrapper5">
        <div class="container px-5">
          <div class="row justify-content-center ">
            <div class="col-md-10 pt-5 text-start">
              <p>In association with <strong>Knysna Fine Art</strong> and <strong>Everard Read</strong>, The Gallery has become renowned for its commitment to excellence, providing a platform for artists dedicated to pushing the boundaries of their craft. From the contemporary expressions of Lionel Smith to the moving drawings and prints of Phillemon Hlungwani and the artistic prowess of Ayanda Mabulu, it truly is a celebration of both local and international art disciplines.</p>
              <p>The gallery is committed to showcasing multiple forms of art, including paintings, mixed media, sculptures and ceramics. Most recently, AD-Reflex, an award-winning art duo formed in 2015 by South African artists Johan Conradie and Karl Gustav Sevenster, created art that merges traditional painting with digital elements and 3-D modelling techniques. Their work blurs the lines between the painterly and the digital, resulting in a unique visual language that is both contemporary and grounded in the present.</p>
              <p>The art also tells stories that move the viewer beyond the canvas. Jan Tshikhuthula’s “Tshikhuwan” takes us back in time to when his grandfather worked on industrial water systems. His landscapes invariably feature windmills, along with unusually gritty water machinery and equipment, which contrast strikingly with the sweeping colours and moods of the Venda region in Limpopo.</p>
              <p>Chas Everitt Luxury’s offices in Hyde Park, Johannesburg, are honoured to have been chosen to exhibit a selection of the gallery’s finest pieces, and you are welcome to visit to see which art pieces from the gallery could enhance the decor in your next luxury home. We are at Shop 12, Hyde Square Shopping Centre, c/o Jan Smuts Avenue and North Road.</p>
              <p>For more information or if you have any questions, feel free to contact us on +27 65 048 1667 or <a href="mailto:david.field@everitt.co.za">david.field@everitt.co.za</a>.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-5 mb-5">
        <div class="row justify-content-center">
          <div class="col-md-4 text-end">
            <p><small><strong>South African Equestrian Estates</strong><br/><a style="color: #000;" href="/article/south-african-equestrian-estates"><i class="bi bi-arrow-left"></i> Previous</a></small></p>
          </div>
          <div class="col-md-1">
            <img style="border-bottom-left-radius: 30px;" src="/images/lifestyle/blog-polo.png" class="img img-fluid">
          </div>
          <div class="col-md-1">
            <img style="border-top-right-radius: 30px;" src="/images/lifestyle/10-must-have-features.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4">
            <p><small><strong>10 must-have features for luxury homes in 2024</strong><br/><a style="color: #000;" href="/article/10-must-have-features">Next <i class="bi bi-arrow-right"></i></a></small></p>
          </div>
        </div>
      </div>

    </section>

    <section v-if="this.$route.params.articleId === '10-must-have-features'">
      
      <div class="container-fluid g-0 bg-light pt-4 pb-4">
        <div class="row g-0">
          <div class="col-md-7">
            <img src = "/images/lifestyle/10-must-have-features.jpg" class="w-100" style="border-bottom-right-radius: 30px;" />
          </div>
          <div class="col-md-4 p-5">
            <h2 class="text-start text-uppercase"><strong>10 must-have features for luxury homes in 2024</strong></h2>
            <p>Certain features are standards in every home, such as a bedroom and a kitchen. However, when you enter the realm of luxury living, high-end and sophisticated features become the hallmark of what it means to truly live the lifestyle.</p>
          </div>
        </div>
      </div>
            
      <div class="wrapper5">
        <div class="container px-5">
          <div class="row justify-content-center ">
            <div class="col-md-10 pt-5 text-start">

              <h2>As technology evolves, so should the features in your home. These are 10 features that are essential to creating a luxury home in 2024.</h2>

              <p class="mt-5"><strong>1. Smart home automation</strong></p>
              <p>Home automation is relatively new technology. It lets you control your home through an app, essentially. You can set your living room and patio lights to automatically turn on at sunset and turn off at sunrise, your coffee maker to start brewing coffee minutes before you wake up, and your front door to lock itself every night. And that’s only the tip of the iceberg.</p>
              <p>In South Africa, home automation is rapidly gaining popularity, and for good reason. If you forget to lock your windows, or set your alarm at night, smart home automation systems ensure that all your security apparatus will work when they’re meant to, without human intervention.</p>

              <p class="mt-5"><strong>2. Innovative windows</strong></p>
              <p>A window is no longer just a looking glass into the world outside. Windows in 2024 now offer built-in features like adjustable tinting or automated blinds.</p>
              <p>And as every South African looks for ways to move further off the public electricity grid, windows with photovoltaic glass could become a handy solution. This glass converts ultraviolet and infrared to electricity while transmitting visible light into building interiors, enabling a more sustainable and efficient use of natural daylight.</p>

              <p class="mt-5"><strong>3. Spa-like bathroom</strong></p>
              <p>Bring that spa feeling into your bathroom with very intentional design principles. The ambiance can distinguish a bathroom, setting the scene, easing you into a serene space. Consider installing chroma therapy lighting – it’s known to enhance a person’s mood and well-being.</p>
              <p>Beyond that, building a freestanding soaking tub next to living walls of carefully selected plants can bring a natural element into the interior design.</p>

              <p class="mt-5"><strong>4. Cold plunge pool</strong></p>
              <p>Often found in luxury spas and wellness centres, plunge pools are quickly becoming a must-have in luxury residences. The temperature in these pools is kept at around 7 to 12 ºC. They’re not intended for leisurely swims; they’re designed as rapid cooling experiences that reinvigorate the body.</p>
              <p>Endurance athlete Wim Hof notes that “scientists have found that exposure to cold speeds up metabolism and reduces inflammation, swelling, and sore muscles.”</p>

              <p class="mt-5"><strong>5. Luxury in the outdoors</strong></p>
              <p>Luxury features extend beyond just the indoors; they also apply to your garden and outdoor spaces. Expansive patios with outdoor kitchens, fireplaces, and infinity pools create an inviting atmosphere for entertaining or simply enjoying the outdoors.</p>
              <p>Some luxury designers are using wooden-style tiles throughout the deck surrounding a pool and thereafter add a sunken outdoor seating area within the pool to bring continuity throughout the garden’s landscape design.</p>

              <p class="mt-5"><strong>6. Biophilic design</strong></p>
              <p>Biophilic design principles integrate nature into the home using natural materials like wood and stone, ample greenery with houseplants or vertical gardens, and features that maximise natural light and ventilation.</p>
              <p>Biophilic design is inspired by the belief that people will experience greater happiness if their interior living spaces are meaningfully integrated with the natural world outside.</p>

              <p class="mt-5"><strong>7. A kitchen with looks to impress</strong></p>
              <p>In the past, living rooms were the place to socialise. The kitchen has adopted that role. A luxury kitchen is not only an inspirational and beautiful space, but incredibly functional at the same time. Consider installing built-in espresso machines or walk-in pantries to elevate your kitchen to the next level.</p>
              <p>If you’re open to doing some major construction in this space, nothing says luxury quite like a bold kitchen countertop, especially when you let it waterfall over the sides of the island. Natural stone slabs are inherently precious since no two look exactly alike.</p>

              <p class="mt-5"><strong>8. A home bar</strong></p>
              <p>The allure of having a personal, stylish bar at home means you’ll have a dedicated space to relax, entertain and unwind. The rise of home bars signifies a shift in modern lifestyles, emphasising the desire for entertainment within the comfort of one’s own space.</p>
              <p>Using plush leather stools, gleaming glassware displayed on open shelves, and mood lighting – you can easily elevate the aesthetic of your living or dining room.</p>

              <p class="mt-5"><strong>9. Bring the cinema home</strong></p>
              <p>For movie buffs and Netflix lovers, a dedicated home theatre is a must-have. State-of-the-art sound systems, comfortable seating, and high-definition screens create an immersive experience that rivals the best movie cinemas.</p>

              <p class="mt-5"><strong>10. Eco-friendly living</strong></p>
              <p>A not-so-well-known feature of luxury homes is the push towards greener living, and architectural practices that promoter a healthier world for all.</p>

              <p>Architects and designers are building homes using locally sourced materials and utilising the power of the sun and wind to generate energy. They’re also installing technology that helps the homeowner offset their carbon footprint, such as humidity-controlled indoor air, filtered drinking water, LED lighting and air filters.</p>

              <p class="mt-5"><strong>Transform your home into a luxury abode</strong></p>
              <p>By incorporating these features, your luxury home won't just be a place to live; it will be a haven that caters to your well-being, entertains your passions, and reflects your unique style.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-5 mb-5">
        <div class="row justify-content-center">
          <div class="col-md-4 text-end">
            <p><small><strong>South African Equestrian Estates</strong><br/><a style="color: #000;" href="/article/south-african-equestrian-estates"><i class="bi bi-arrow-left"></i> Previous</a></small></p>
          </div>
          <div class="col-md-1">
            <img style="border-bottom-left-radius: 30px;" src="/images/lifestyle/blog-polo.png" class="img img-fluid">
          </div>
          <div class="col-md-1">
            <img style="border-top-right-radius: 30px;" src="/images/lifestyle/steyn-city.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4">
            <p><small><strong>The Gallery at Steyn City</strong><br/><a style="color: #000;" href="/article/the-gallery-steyn-city">Next <i class="bi bi-arrow-right"></i></a></small></p>
          </div>
        </div>
      </div>

      <!-- <div class="container-fluid">
        <div class="row bg-light">
          <div class="col-md-8">
            <p class="py-5"><img style="border-top-right-radius: 30px;" src="https://d3nqlcsikcdie5.cloudfront.net/uploads/listings/2023/05/e9ae439e208036f.jpg" class="img img-fluid"></p>
          </div>
          <div class="col-md-4 pt-5 mt-5 px-4">
            <h2><strong>Val de Vie</strong></h2>
            <h2>A South African Equestrian Estate</h2>
            <p><small>Image taken from Portfolio Property in Val de Vie, Paarl</small></p>
            <p><a class="btn filter-btn my-5" href="/property/2765013">View this property now</a></p>
            </div>
        </div>
      </div> -->

    </section>

    <section v-if="this.$route.params.articleId === 'south-african-equestrian-estates'">
      
      <div class="container-fluid g-0 bg-light pt-4 pb-4">
        <div class="row g-0">
          <div class="col-md-7">
            <img src = "/images/lifestyle/blog-polo.png" class="w-100" style="border-bottom-right-radius: 30px;" />
          </div>
          <div class="col-md-4 p-5">
            <h2 class="text-start text-uppercase"><strong>Which South African equestrian estate should you invest in?</strong></h2>
            <p>South Africa is a country that offers many interesting property investment opportunities for local and overseas buyers. One of those lucrative opportunities is our beautiful and vast selection of equestrian estates.</p>
          </div>
        </div>
      </div>
            
      <div class="wrapper5">
        <div class="container px-5">
          <div class="row justify-content-center ">
            <div class="col-md-10 pt-5 text-start">
              <p><strong>South Africa is a global equestrian powerhouse</strong></p>
              <p>Beyond its breathtaking landscapes and vibrant culture, South Africa boasts a thriving equestrian scene. Renowned for its dedication to horse breeding and hosting prestigious international events, the country offers a haven for discerning riders.</p>
              <p><strong>Equestrian estates are all over the country</strong></p>
              <p>In the luscious Cape region, there are abundance of equestrian estates, such as Val de Vie, High Riding, Tre Donne in The Winelands, Wedderwill in Helderberg and Klein Zevenwacht in Kuilsriver.</p>
              <p>In the business hub of Gauteng, you’ll find equestrian estates such as Inanda Country base, Kyalami Equestrian Park, Saddlebrook, Beaulieu and Blue Hills.</p>
              <p>The warm seaside province of KZN boasts Seaton Estate, Oaklands Equestrian Estate and Stoneford Country Estate, just to name a few.</p>
              <p>In terms of the percentage breakdown, over 50% of all equestrian estates are in Gauteng compared to 25% in the Western Cape. The other 25% is split amongst the other provinces.</p>
              <p><strong>It is a growing market with a global appeal</strong></p>
              <p>Astute investors appreciate South Africa's growing property market and favourable exchange rate. Here, luxury estates present a compelling proposition for international buyers. Imagine owning a piece of paradise for a fraction of the cost you might expect elsewhere.</p>
              <p><strong>Living on an equestrian estate is like paradise on earth</strong></p>
              <p>Equestrian estates redefine horse ownership. Forget the daily chores; immaculate stables with resolute staff ensure your horses are pampered. Imagine stepping out your door and directly onto pristine riding arenas, lunging rings, or embarking on invigorating outrides through breathtaking scenery – all within a secure and exclusive community.</p>
              <p><strong>Each estate has its own charm</strong></p>
              <p>South African equestrian estates all offer something unique. For example, Waterfall Equestrian Estate exemplifies the pinnacle of luxury, offering sprawling properties with breathtaking views, world-class amenities like polo fields and private schools, and unparalleled security. Picture unwinding after a ride at your private pool, surrounded by the majesty of the Magaliesberg mountains.</p>
              <p><strong>The key factors to consider when making your decision</strong></p>
              <p>While the allure of luxury is undeniable, choosing the right estate hinges on a strategic approach. Here are key factors to consider:</p>
              <ul style="color: #000;">
                <li>Location: South Africa offers estates in various regions. Gauteng, home to Kyalami, is a renowned equestrian hub, while the Western Cape boasts scenic beauty. Consider your proximity to desired amenities and equestrian events.</li>
                <li>Budgeting for Success: Luxury estates range in price. Determine your budget for the property itself, as well as ongoing costs like stabling fees and maintenance.</li>
                <li>Horse-Centric Considerations: Identify your horses' specific needs, such as space requirements and any specialized facilities they might require.</li>
                <li>Lifestyle Preferences: Do you crave a vibrant community or a serene retreat? Estates offer a variety of amenities beyond equestrian facilities; explore options with clubhouses, tennis courts, padel courts, or even on-site healthcare.</li>
              </ul>
              <p><strong>Invest in your passion</strong></p>
              <p>Investing in a South Africa equestrian estate is more than just a property purchase; it is a strategic decision that fuels your passion for horses. Imagine a life intertwined with your prized possessions, surrounded by beauty, luxury, and a community that shares your equestrian dreams.</p>
              <p><strong>Ready to make your move?</strong></p> 
              <p>Consult with one of our qualified luxury real estate agents specialising in equestrian properties. Their expertise will guide you through the selection process, ensuring your find the right estate for your needs.</p>
              <p><a href="/contact" style="text-decoration: none;"><strong>Contact us today</strong></a> to turn your equestrian dreams into a reality.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-5 mb-5">
        <div class="row justify-content-center">
          <div class="col-md-4 text-end">
            <p><small><strong>Spotlight on Plettenberg Bay</strong><br/><a style="color: #000;" href="spotlight-on-plettenberg-bay"><i class="bi bi-arrow-left"></i> Previous</a></small></p>
          </div>
          <div class="col-md-1">
            <img style="border-bottom-left-radius: 30px;" src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801896/Photos,52211/pic_52211191.jpg" class="img img-fluid">
          </div>
          <div class="col-md-1">
            <img style="border-top-right-radius: 30px;" src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801877/Photos,55037/pic_55037049.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4">
            <p><small><strong>Short-term real estate investment</strong><br/><a style="color: #000;" href="/article/short-term-real-estate-investments-exploring-the-potential-of-airbnb-house-flipping">Next <i class="bi bi-arrow-right"></i></a></small></p>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row bg-light">
          <div class="col-md-8">
            <p class="py-5"><img style="border-top-right-radius: 30px;" src="https://d3nqlcsikcdie5.cloudfront.net/uploads/listings/2023/05/e9ae439e208036f.jpg" class="img img-fluid"></p>
          </div>
          <div class="col-md-4 pt-5 mt-5 px-4">
            <h2><strong>Val de Vie</strong></h2>
            <h2>A South African Equestrian Estate</h2>
            <p><small>Image taken from Portfolio Property in Val de Vie, Paarl</small></p>
            <p><a class="btn filter-btn my-5" href="/property/2765013">View this property now</a></p>
            </div>
        </div>
      </div>

    </section>

    <section v-if="this.$route.params.articleId === 'exploring-the-cape-peninsula'">
      
      <div class="container-fluid g-0 bg-light pt-4 pb-4">
        <div class="row g-0">
          <div class="col-md-7">
            <img src = "/images/lifestyle/weekend-getaways.jpg" class="w-100" style="border-bottom-right-radius: 30px;" />
          </div>
          <div class="col-md-4 p-5">
            <h2 class="text-start"><strong>5 WEEKEND GETAWAYS</strong></h2><br/>
            <h2>EXPLORING THE CAPE PENINSULA FROM HOUT BAY</h2>
          </div>
        </div>
      </div>
            
      <div class="wrapper5">
        <div class="container px-5">
          <div class="row justify-content-center ">
            <div class="col-md-10 pt-5 text-start">
              <p>Hout Bay has become a popular destination for property semigration, with many new families having relocated from the likes of Johannesburg and Pretoria over the last few years.  And it's easy to understand why. Nestled between the mountains and the Atlantic Ocean, it offers more than stunning beachfront properties and a tranquil lifestyle. It is a gateway to some of the most breathtaking weekend getaways in the Cape Peninsula. For those who live or are considering moving to Hout Bay, the peninsula presents a treasure trove of adventures just a short drive away.</p>
              <h2 style="text-align: start;" class="pt-4 pb-4">Here are five weekend getaways that you can enjoy</h2>
              <p><strong>Chapman's Peak Drive - A Scenic Wonder</strong></p>
              <p>Begin your weekend with a drive on the famous Chapman's Peak Drive. Known for its panoramic views, it is a marvel of engineering and offers some of the best sunset spots. Make sure to stop at the viewpoints and take in the vistas of the ocean and the surrounding mountains.</p>
              <p><strong>Simon's Town and Boulders Beach - A Date with Penguins</strong></p>
              <p>Next, head to Simon's Town, a charming naval village rich in history and culture. Don't forget to visit Boulders Beach, home to a colony of African penguins. Watching these adorable creatures in their natural habitat makes for a perfect family outing.</p>
              <p><strong>Cape Point - Where Two Oceans Meet</strong></p>
              <p>Further down the road lies Cape Point, a nature reserve within the Table Mountain National Park. The dramatic cliffs, wild beaches, and the mythic meeting point of the Indian and Atlantic Oceans are awe-inspiring. Hike or ride the funicular to the old lighthouse for a panoramic view you will remember.</p>
              <p><strong>Kalk Bay - A Bohemian Rhapsody</strong></p>
              <p>On your way back, stop at Kalk Bay, a bohemian paradise known for its eclectic shops, art galleries, and superb seafood. Stroll along the harbour, watch the fishermen at work, and spot a seal.</p>
              <p><strong>Constantia Wine Route - A Toast to the Weekend</strong></p>
              <p>End your weekend with a visit to the Constantia Wine Route. This historic wine region, just a short drive from Hout Bay, boasts some of the finest vineyards and oldest estates in South Africa. Enjoy a leisurely lunch and wine tasting while overlooking the vine-covered hills.</p>
              <p>Hout Bay is a place to call home and a launchpad for countless adventures and memories. These weekend getaways blend nature, history, and culinary delights, ensuring there is always something new to explore and experience. Whether you are a resident or planning to buy property in Hout Bay, the Cape Peninsula is your backyard, waiting to be discovered.</p> 
              </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-5 mb-5">
        <div class="row justify-content-center">
          <div class="col-md-4 text-end">
            <p><small><strong>Spotlight on Plettenberg Bay</strong><br/><a style="color: #000;" href="spotlight-on-plettenberg-bay"><i class="bi bi-arrow-left"></i> Previous</a></small></p>
          </div>
          <div class="col-md-1">
            <img style="border-bottom-left-radius: 30px;" src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801896/Photos,52211/pic_52211191.jpg" class="img img-fluid">
          </div>
          <div class="col-md-1">
            <img style="border-top-right-radius: 30px;" src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801877/Photos,55037/pic_55037049.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4">
            <p><small><strong>Short-term real estate investment</strong><br/><a style="color: #000;" href="/article/short-term-real-estate-investments-exploring-the-potential-of-airbnb-house-flipping">Next <i class="bi bi-arrow-right"></i></a></small></p>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row bg-light">
          <div class="col-md-8">
            <p class="py-5"><img style="border-top-right-radius: 30px;" src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801865/Photos,57563/pic_57563459.jpg" class="img img-fluid"></p>
          </div>
          <div class="col-md-4 pt-5 mt-5 px-4">
            <h2><strong>Hout Bay</strong></h2>
            <h2>Your launchpad to the Cape Peninsula</h2>
            <p><small>Image taken from Portfolio Property in Northshore, Hout Bay</small></p>
            <p><a class="btn filter-btn my-5" href="/property/4052941">View this property now</a></p>
            </div>
        </div>
      </div>

    </section>

    <section v-if="this.$route.params.articleId === 'short-term-real-estate-investments-exploring-the-potential-of-airbnb-house-flipping'">
      <div class="container-fluid g-0 bg-light pt-4 pb-4">
        <div class="row g-0">
          <div class="col-md-7">
            <img src = "https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801877/Photos,55037/pic_55037049.jpg" class="w-100" style="border-bottom-right-radius: 30px;" />
          </div>
          <div class="col-md-4 p-5">
            <h2 class="text-start"><strong>SHORT-TERM REAL ESTATE INVESTMENTS</strong></h2><br/>
            <h2>EXPLORING THE POTENTIAL OF AIRBNB & HOUSE FLIPPING</h2>
          </div>
        </div>
      </div>
            
      <div class="wrapper5">
        <div class="container px-5">
          <div class="row justify-content-center ">
            <div class="col-md-10 pt-5 text-start">         
              <p>Investing in real estate has long been recognised as a safe strategy to generate revenue. Short-term real estate investments, in particular, have gained popularity in the past few years due to their flexible nature and higher earning potential. </p>
              <p>"Popular strategies for short-term property investments include the ever-popular Airbnb and flipping houses," says Andre van der Merwe, property development specialist at Chas Everitt. "While these can prove lucrative, just as any other property investment, potential buyers should carefully consider the price versus the expected returns." </p>
              <p>This blog explores the advantages, drawbacks, and advice on these strategies. </p>
              <p><strong>Airbnbs</strong></p>
              <p>It's an unassailable fact that the hospitality industry has been revolutionised by the advent of Airbnb. This short-term real estate investment involves renting out an owned property as short-term holiday accommodation. Since its launch, the site has boomed in popularity leading forward-thinking individuals to invest in character properties or properties in desirable locations with the sole intention of renting them out on Airbnb. </p>
              <p>Here are some of the advantages of this strategy: </p>
              <ul>
                <li>
                  <p>Higher earnings than if your property were leased long-term</p>
                </li>
                <li>
                  <p>More flexibility, as you can determine when the property is available </p>
                </li>
                <li>
                  <p>The ability to vet guests</p>
                </li>
              </ul>
              <p>Here are some drawbacks to becoming an Airbnb host:</p>
              <ul>
                <li>
                  <p>Stress of managing a property</p>
                </li>
                <li>
                  <p>Revenue is not guaranteed and can fluctuate seasonally</p>
                </li>
                <li>
                  <p>Unforeseen expenses such as higher utility bills</p>
                </li>
              </ul>
              <p>Advice for maximising Airbnb returns</p>
              <ul>
                <li>
                  <p>Location, location, location</p>
                </li>
              </ul>
              <p>Before investing in a property with the intention of creating an Airbnb, do extensive research on the market and look into the demand for rental properties  within the area. Investing in a property close to popular attractions such as town centres, transport links, historical landmarks, or natural wonders is always a good bet. </p>
              <ul>
                <li>
                  <p>Check out the competition </p>
                </li>
              </ul>
              <p>Look into the other holiday accommodation that is available in the area. Think about what will set your property apart and how you will compete for guests. </p>
              <ul>
                <li>
                  <p>Become a Superhost </p>
                </li>
              </ul>
              <p>Superhost is a title given to hosts who consistently deliver a high-quality stay. It's based on several criteria ranging from how quickly you respond to inquiries to the number of night stays you've had. Becoming a Superhost means that your listing will be more visible to potential guests and is likely to boost your occupancy rate. </p>
              <p> </p>
              <p><strong>House flipping</strong></p>
              <p>This strategy involves acquiring a property with the intention of renovating and remodelling it in order to sell it at a higher price. Investors will purchase a property that has issues and add value by correcting those issues and making strategic improvements. It's a trend that has been on an upward trajectory since the early 2000s and shows no signs of tapering off. </p>
              <p>Here are some of the advantages of this strategy: </p>
              <ul>
                <li>
                  <p>Quick profit through higher returns in a short period of time </p>
                </li>
                <li>
                  <p>It's a safe investment as the property market is more predictable than other markets </p>
                </li>
                <li>
                  <p>Enables you to build a variety of skills - from DIY to networking </p>
                </li>
              </ul>
              <p>Here are some drawbacks to house flipping:</p>
              <ul>
                <li>
                  <p>Unforeseen expenses can arise and impact profits</p>
                </li>
                <li>
                  <p>High barrier to entry as it can be expensive </p>
                </li>
                <li>
                  <p>Competition, which makes it difficult to find homes with ample opportunities </p>
                </li>
              </ul>
              <p>Advice for maximising returns on flipped houses</p>
              <ul>
                <li>
                  <p>Scope to extend </p>
                </li>
              </ul>
              <p>Ensure you research what type of renovations are permitted on the property and will be able to secure planning permission. It's also important to assess the feasibility of any additions you would like to make and if the property has the space and existing utility lines to support the planned alterations. </p>
              <ul>
                <li>
                  <p>Focus on budget</p>
                </li>
              </ul>
              <p>Carefully plan your budget so you can accurately assess the viability of a project. Consider all costs and make sure the budget can accommodate worst-case scenarios, such as fluctuations in the cost of building materials or supply chain shortages. </p>
              <ul>
                <li>
                  <p>Point of sale </p>
                </li>
              </ul>
              <p>Selling the property is the last but arguably one of the most important steps in the house flipping process. To ensure that the hard work of the renovation does not go to waste, make sure to stage the home appropriately and entrust the marketing and sale to a dedicated estate agent.  </p>
              <p>Both short-term investments offer incredible opportunities for capitalising on the dynamism that characterises the real estate market. For expert advice on property investments, reach out to a dedicated <a href="/agents/" style="color: #000;">Chas Everitt property practitioner</a>. </p>
            
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-5 mb-5">
        <div class="row justify-content-center">
          <div class="col-md-4 text-end">
            <p><small><strong>Exploring the Cape Peninsula</strong><br/><a style="color: #000;" href="/article/exploring-the-cape-peninsula"><i class="bi bi-arrow-left"></i> Previous</a></small></p>
          </div>
          <div class="col-md-1">
            <img style="border-bottom-left-radius: 30px;" src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801865/Photos,57563/pic_57563459.jpg" class="img img-fluid">
          </div>
          <div class="col-md-1">
            <img style="border-top-right-radius: 30px;" src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801896/Photos,52211/pic_52211165.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4">
            <p><small><strong>Spotlight on Plettenberg Bay</strong><br/><a style="color: #000;" href="/article/spotlight-on-plettenberg-bay">Next <i class="bi bi-arrow-right"></i></a></small></p>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-5">
        <div class="row bg-light">
          <div class="col-md-8">
            <p class="py-5"><img style="border-top-right-radius: 30px;" src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801877/Photos,55037/pic_55037048.jpg" class="img img-fluid"></p>
          </div>
          <div class="col-md-4 pt-5 mt-5 px-4">
            <h2><strong>Sandton</strong></h2>
            <h2>"Investing in real estate has long been recognised as a safe strategy to generate revenue"</h2>
            <p><small>Images taken from Portfolio Property in Sandton, Johannesburg.</small></p>
            <p><a class="btn filter-btn my-5" href="/property/3918301">View this property now</a></p>
            </div>
        </div>
      </div>
    </section>

    <section v-if="this.$route.params.articleId === 'spotlight-on-plettenberg-bay'">
      <div class="container-fluid g-0 bg-light pt-4 pb-4">
        <div class="row g-0">
          <div class="col-md-7">
            <img src = "https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801896/Photos,52211/pic_52211165.jpg" class="w-100" style="border-bottom-right-radius: 30px;" />
          </div>
          <div class="col-md-4 p-5">
            <h2 class="text-start"><strong>SPOTLIGHT</strong></h2><br/>
            <h2>On Plettenberg Bay</h2>
            <p class="pt-4"><em>"Did you know that Plettenberg Bay (or simply Plett, as most South Africans call it) is one of the five most expensive places to buy luxury property in the whole of Africa?"</em></p>
          </div>
        </div>
      </div>
            
      <div class="wrapper5">
        <div class="container px-5">
          <div class="row justify-content-center ">
            <div class="col-md-10 pt-5 text-start">
              <p><strong>According to the latest research by Henley & Partners and New World Wealth</strong>, Cape Town is the most expensive place for Africa's super-wealthy to acquire a prime 200sqm to 400sqm apartment (at an average of around R100 000/sqm), followed by Plett and Hermanus, tied at around R42 000/sqm for prime apartments, and then by Umhlanga and Marrakech in Morocco, tied at R38 000/sqm.</p>
              <p>Most apartments in Plett that would fall into this category are located in luxury sectional title developments close to the centre of town, or close to either Lookout Beach or Robberg Beach, or in the Goose Valley Golf Estate.</p>
              <p>But of course the town also boasts many high-end freehold properties, located on the famous Beachy Head Drive or in areas such as Keurboomstrand, Signal Hill, Robberg Ridge, Cutty Sark and Whale Rock as well as estates like Goose Valley, The Hill and The Crags. </p>
              <p>Always a popular holiday destination for affluent South Africans, Plett has more recently also become a hotspot for top professionals and executives semigrating permanently from Gauteng and KwaZulu-Natal, as well as an increasing number of high-net worth foreigners who have discovered the allure of the spectacular Garden Route along SA's southern Cape coast.</p>
              <p>And that is no surprise, says Dean Meijer, who recently took over the multiple award-winning Chas Everitt franchise in Plett from John Fuller, who has retired after 18 years at the helm. </p>
              <p>Meijer, who is also the owner of the top-performing Chas Everitt Whale Coast and Winelands franchises, notes that while Plett currently gets less attention than Cape Town and his hometown of Hermanus when it comes to semigration, astute buyers are increasingly aware of the incredible lifestyle they can enjoy here for much less than in Cape Town.</p>
              <p>"This area is known for its stunning natural beauty, with pristine golden beaches, lush forests and a mild climate that encourages outdoor activities such as birdwatching, cycling, hiking, whale-watching, watersports on the Keurbooms Lagoon and golf on several top-class courses, including the Gary Player Signature Course at Goose Valley. Several local wine farms are also gaining a reputation for their sparkling wines, and the renowned Kurland polo estate is the centre of much equestrian activity in the area.</p>
              <p>"In addition, Plett offers prospective homebuyers excellent primary and high schools, several shopping centres that are home to most major national retailers, trendy restaurants, a first-class private hospital and its own airport. It is also a well-run town and is of course still in the Western Cape, which is the best-managed region in SA.</p>
              <p>"And the increasing interest among these savvy buyers is evident in the fact that home sales here have been rising steeply ever since the Covid-19 pandemic. This rising demand has resulted in something of a stock shortage currently, and prices have also risen remarkably."</p>
              <p>When Chas Everitt Plettenberg Bay opened in 2005, a five-bedroom, three-bathroom home with a fabulous Indian Ocean view on Beachy Head Drive was priced at R4,34m. Today a very similar home in that location is priced at more than R8m, he notes.</p>
              <p>"But a huge amount of that growth has actually taken place just in the past few years, when the oversupply of property that has dogged the local market for many years - and kept a lid on prices - was finally taken up. </p>
              <p>"Consequently, property in Plett is still relatively affordable, even for those who are not in the super wealthy bracket, with building stands in local lifestyle estates now starting at around R500 000, and one-bedroom apartments in good locations available at prices from around R1m to R1,2m. Prices for older three to four bedroom homes in Plett Central or the suburbs start at around R1,35m, while family size homes in gated estates start at around R3,6m.</p>
              <p>"And for those who are in the luxury market, the top asking price for the high-end homes currently on sale in Plett is R29m, which is way less than the prices being asked for equivalent properties on the Atlantic Seaboard or in the Southern Suburbs of Cape Town, so we are expecting the demand here to remain high as the town is 'discovered' by more and more buyers seeking a great lifestyle at a more affordable price."</p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-5 mb-5">
        <div class="row justify-content-center">
          <div class="col-md-4 text-end">
            <p><small><strong>Exploring the Cape Peninsula</strong><br/><a style="color: #000;" href="/article/exploring-the-cape-peninsula"><i class="bi bi-arrow-left"></i> Previous</a></small></p>
          </div>
          <div class="col-md-1">
            <img style="border-bottom-left-radius: 30px;" src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801896/Photos,52211/pic_52211191.jpg" class="img img-fluid">
          </div>
          <div class="col-md-1">
            <img style="border-top-right-radius: 30px;" src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801877/Photos,55037/pic_55037049.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4">
            <p><small><strong>Short-term real estate investment</strong><br/><a style="color: #000;" href="/article/short-term-real-estate-investments-exploring-the-potential-of-airbnb-house-flipping">Next <i class="bi bi-arrow-right"></i></a></small></p>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row bg-light">
          <div class="col-md-8">
            <p class="py-5"><img style="border-top-right-radius: 30px;" src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801896/Photos,52211/pic_52211191.jpg" class="img img-fluid"></p>
          </div>
          <div class="col-md-4 pt-5 mt-5 px-4">
            <h2><strong>Plettenberg Bay</strong></h2>
            <p><small>Images taken from Portfolio Property in Cutty Sark, Plettenberg Bay</small></p>
            <p><a href="/property/3765756" class="btn filter-btn my-5">View this property now</a></p>
            </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>
<script>
import Menu from '@/components/home/Menu.vue'
import Footer from '@/components/home/Footer.vue'
import LifestyleFeed from '@/components/home/LifestyleFeed.vue'
export default {
  components: {
    Menu, Footer, LifestyleFeed
  },
  computed: {
    currentUrl() {
      return encodeURIComponent(window.location.href)
    },
    facebookShareLink() {
      // const text = encodeURIComponent(this.listing.data.listing[0].marketing_heading);
      return `https://www.facebook.com/sharer/sharer.php?u=${this.currentUrl}`
    },
    pinterestShareLink() {
      if (this.$route.params.articleId === 'exploring-the-cape-peninsula') {
        const image = encodeURIComponent("https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801865/Photos,57563/pic_57563459.jpg");
        return `http://pinterest.com/pin/create/bookmarklet/?media=${image}&url=${this.currentUrl}&is_video=false`
      }
      if (this.$route.params.articleId === 'short-term-real-estate-investments-exploring-the-potential-of-airbnb-house-flipping') {
        const image = encodeURIComponent("https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801877/Photos,55037/pic_55037049.jpg");
        return `http://pinterest.com/pin/create/bookmarklet/?media=${image}&url=${this.currentUrl}&is_video=false`
      }
      if (this.$route.params.articleId === 'spotlight-on-plettenberg-bay') {
        const image = encodeURIComponent("https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801896/Photos,52211/pic_52211165.jpg");
        return `http://pinterest.com/pin/create/bookmarklet/?media=${image}&url=${this.currentUrl}&is_video=false`
      }
    },
  },
  data() {
    return {
      data: {

      }
    }
  },
  mounted () {
    console.log(this.$route.params.articleId)
  }
}
</script>
<style scoped>

.force-height {
  height: 300px;
  object-fit: cover;
}
  .expanded {
    transform: scale(1.2);
    z-index: 1;
  }
  .social-links{
  position: unset;
  display: inline;
  float: right;
}
.fixed-tags {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 1000;
}
.fixed-enquire {
  position: fixed;
  top: 20%;
  right: 20px;
  z-index: 1000;
}
iframe {
  border-bottom-right-radius: 30px;
}
</style>